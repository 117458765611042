<template>
  <div class="img-container">
    <img class="category-thumbnail rounded"
           :src="category.category_img_url"
           alt="No Image Found"
           :style="{height: imgContainerHeight + 'px'}"
           v-if="category.category_img_url !== null"
    />

    <img class="category-thumbnail rounded"
         src="../../../assets/images/hh_contact.jpg"
         alt="No Image Found"
         :style="{height: imgContainerHeight + 'px'}"
         v-else/>
    <p class="category-name"> {{ category.category_title }} </p>
    <div class="overlay">
      <router-link v-if="isClickable"  class="icon" :to="`/media-list/${toUrl}`">
        <i class="fa fa-play"></i>
      </router-link>
      <div v-else> 
        <router-link class="icon" :to="`/subscription`">
        <i class="fa fa-lock icon"></i>
        </router-link>
        <h3>Subscribe to Unlock</h3>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props : {
      category : Object,
    },
    data() {
      return {
        imgContainerWidth  : 0,
        imgContainerHeight : 0,
        toUrl              : this.category.category_id + '/' +
          this.category.category_title,
        planId : this.$store.getters['subscription/subscription'].plan_id,
      }
    },
    watch : {
      imgContainerWidth : function() {
        this.imgContainerHeight = this.imgContainerWidth * 0.625; // 16:10
      },
    },
    computed : {
      isClickable : function(){
        if(this.planId > 1)
          return true;
        else if(this.category.has_free)
          return true;

        return false;
      },
    },
    methods : {

      /**
       * Get Image's Container Width
       */
      getImgContainerWidth() {
        this.$nextTick(() => {
          const $container = this.$el;
          if ($container) this.imgContainerWidth = $container.clientWidth;
        });
      },
    },
    mounted() {
      this.getImgContainerWidth();
    },
    updated() {
      this.getImgContainerWidth();
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/user/common/app-categories';
</style>
